import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { Link } from 'gatsby';
import _ from 'lodash';

export default styled(({ className, tags }) => (
  <div className={cx(className, 'bl-tags')}>
    <div className="taglist">
      {_.castArray(tags || []).map(tag => {
        if(_.isString(tag)) {
          return (
            <div className="tag" key={tag + `tag`}>
              <Link to={`/tags/${_.kebabCase(tag)}/`}>{tag}</Link>
            </div>
          );
        }
        if(_.isPlainObject(tag)) {
          return (
            <div className="tag" key={tag.name + `tag`}>
              <Link to={tag.url}>{tag.name}</Link>
            </div>
          );
        }
        return null;
      })}
    </div>
  </div>
))`
  padding: ${({ theme }) => `0 ${theme.p1}`};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: ${({ theme }) => `10px ${theme.ps1}`};
  }
  .taglist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .tag {
      a {
        color: ${({ theme }) => theme.altPink} !important;
        line-height: 24px;
        font-size: 12px;
        @media (max-width: ${({ theme }) => theme.mobile}) {
          font-size: 10px;
        }
        text-transform: uppercase;
        border-radius: 23px;
        padding: 4px 16px;
      }
      margin: 0px 4px 4px 0px;
      &:nth-child(3n+1) {
        a {
          color: ${({ theme }) => theme.primaryWhite} !important;
          background: #005F64;
        }
      }
      &:nth-child(3n+2) {
        a {
          color: ${({ theme }) => theme.primaryWhite} !important;
          background: #CD5B49;
        }
      }
      &:nth-child(3n) {
        a {
          color: ${({ theme }) => theme.primaryWhite} !important;
          background: #8A7441;
        }
      }
    }
  }
  .block {
    padding-left: ${({ theme }) => theme.p1};
    border-left: ${({ theme }) => theme.divider};
    .quote {
      color: ${({ theme }) => theme.altPink};
      font-size: 50px;
      line-height: 1.2em;
      padding: ${({ theme }) => theme.p1} 0px;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 30px;
      }
    }
    .caption {
      color: ${({ theme }) => theme.primaryBlack};
      font-size: 14px;
      opacity: 0.7;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 11px;
      }
    }
  }
`;
