import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import _ from 'lodash';
import BlogLayout from '../components/BlogLayout'
import { HTMLContent } from '../components/Content'

import BlogPostTemplate from '../components/BlogPostTemplate';
import '../graphql/blogFragment';

const BlogPost = ({ data }) => {
  const frontmatter = _.get(data, 'article.data');
  // build articles from query
  const articles = _.castArray(_.get(data, 'articles.items') || []).map(node => {
    const article = _.get(node, 'data');
    return {
      name: _.get(article, 'title'),
      // url: _.get(article, 'fields.slug'),
      url: `/${article.date}-${_.kebabCase(article.title)}/`,
      thumbnail_url: _.get(article, 'image.0.url'),
      isLiked: true,
      isBookmarked: true,
      articleId: 1,
      likeCount: 10,
      bookmarkCount: 10,
      post: article,
    };
  });

  const post = {
    html: _.get(frontmatter, 'full_copy'),
    rawMarkdownBody: _.get(frontmatter, 'full_copy'),
    frontmatter,
  };

  return (
    <BlogLayout >
      <BlogPostTemplate
        post={post}
        content={post.html}
        contentComponent={HTMLContent}
        description={frontmatter.description}
        tags={frontmatter.tags}
        helmet={
          <Helmet
            titleTemplate="%s | Blog"
          >
            <title>{`${frontmatter.title}`}</title>
            <meta name="description" content={`${frontmatter.description}`} />
          </Helmet>
        }
        title={frontmatter.title}
        image={_.get(frontmatter, 'image.0.url', frontmatter.image)}
        articles={articles}
      />
    </BlogLayout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query AirtableBlogPostByID($id: String!) {
    article: airtable(id: { eq: $id } ) {
      ...blogFragment
    }
    articles: allAirtable(
      filter: {
        table: {in: ["Blog", "News"]},
        data: {status: {eq: "published"}}
      },
      sort: {
        order: DESC, fields: data___date_created
      }
    ) {
      items: nodes {
        ...blogFragment
      }
    }
  }
`
