import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment';

import Content from './Content'

import PageHeader from './Blocks/PageHeader'
import PageImage from './Blocks/PageImage'
import HDivider from './Blocks/HDivider'
// import ArticleSlider from './Blocks/ArticleSlider';
import Tags from './Blocks/Tags';
import Date from './Blocks/Date';
// import SocialButtons from './Blocks/SocialButtons';
// import BlogCard1 from '../components/BlogList/BlogCard1'

export const BlogPostTemplate = ({
  post,
  content,
  contentComponent,
  tags,
  title,
  image,
  helmet,
  articles,
}) => {
  const PostContent = contentComponent || Content
  const date = _.get(post, 'frontmatter.date');
  const tagList = [];
  // category tag list
  const category = _.get(post, 'frontmatter.category');
  category && tagList.push({ name: category, url: `/categories/${_.kebabCase(category)}/` });
  tagList.concat(tags);
  return (
    <section className="bf-blog-post section" style={{maxWidth: "1127px !important", margin: "auto !important" }}>
      {helmet || ''}
      <div className="container content" >
        <div className="columns">
          <div className="column is-10 is-offset-1">
            {_.get(tagList, 'length') && (<Tags tags={tagList} />)}
            <PageHeader text={title} />
            {date && <Date text={moment(date).format("MMMM DD, YYYY")} />}
            {/* <SocialButtons /> */}
            <PageImage image={image} />
            <HDivider />
            <PostContent content={content} post={post} />
            {/* {
              _.get(articles, 'length') && 
              <>
                <HDivider />
                <ArticleSlider
                  title="MORE ADVICE"
                  articles={articles}
                  CardComponent={BlogCard1}
                />
              </>
            } */}
          </div>
        </div>
      </div>
    </section>
  )
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

export default BlogPostTemplate