import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

export default styled(({ className, text }) => (
  <div className={cx(className, 'bl-Date')}>
    <div className="block">
      {text}
    </div>
  </div>
))`
  padding: ${({ theme }) => `0 ${theme.p1}`};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: ${({ theme }) => `10px ${theme.ps1}`};
  }
  .block {
    color: #424242;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;
